export const API_ROUTES_NEXT_PUBLIC_SITE = {
  feedbackFormApiUrl: '/api/feedback',
  deleteSubscriberAccountsApiUrl: '/api/auth/delete-account', // will work for both stripe and propay
  updateSubscriberWithStripeAccountApiUrl: '/api/stripe/create-portal-session',
  createSubscriberWithStripeAccountApiUrl: '/api/stripe/create-checkout-session',
  createCheckoutSessionForGiftApiUrl: '/api/stripe/create-checkout-session-for-gift',
  createProtectpayTransactionIdentifier: '/api/propay/protectpay-create-transaction-identifier',
  createPropaySubscription: '/api/propay/propay-create-subscription',
  cancelPropaySubscription: '/api/propay/propay-cancel-subscription',
  cancelStripeSubscriptionApiUrl: '/api/stripe/cancel-subscription',
  reactivateSubscriptionApiUrl: '/api/stripe/reactivate-subscription',
  applyDiscountApiUrl: '/api/stripe/apply-discount-code',
  subscribeToNewsletterApiUrl: '/api/newsletter/subscribe',
  unsubscribeFromNewsletterApiUrl: '/api/newsletter/unsubscribe',
  revalidate: '/api/revalidate-paths',
  revalidateSiteApiUrl: '/api/revalidate-site',
  chatHistoryMessagesApiUrl: '/api/messages',
  chatHistoryConversationsApiUrl: '/api/conversations',
  loadMoreArticlesApiUrl: '/api/load-more-articles',
  deleteOldCacheEntriesApiUrl: '/api/delete-old-cache-entries',
} as const

export const PAGES_NEXT_PUBLIC_SITES = {
  thankYou: '/thank-you',
  signIn: '/login',
  home: '/',
  notAuthRedirectUrl: '/sign-in',
  manageSubscriptionUrl: '/subscribe?action=manage-subscription',
  paymentPortalResult: '/payment-portal-result',
  signInMagicLinkUrl: '/signin-magic-link',
  gift: '/gift',
} as const
